// src/components/GoogleMap.js

import React, { useState, useRef, useEffect } from 'react';
import { GoogleMap, Marker, DirectionsService, DirectionsRenderer, InfoWindow } from '@react-google-maps/api';
import GoogleMapsLoader from './GoogleMapsLoader';
import { makeApiRequest } from '../../utils/ApiCalls';
import './GoogleMap.css';
import markerIcon from '../../Assets/10.png';
import DetailsBoxUI from '../detailsBoxUI/DetailsBoxUI';
import StepsSidebar from '../stepsSideBar/StepsSidebar';

const center = {
    lat: 37.7749, // Replace with your desired latitude
    lng: -122.4194, // Replace with your desired longitude
};
const customMarkerIcon = {
    url: markerIcon,
    scaledSize: { width: 32, height: 32 },
};
const Map = () => {
    const [data, setData] = useState(null);
    const [zgID, setzgID] = useState("");
    // const [allData, setAllData] = useState([]);
    const [wayPointData, setWayPointData] = useState([]);
    const [openInfoWindowId, setOpenInfoWindowId] = useState(null);
    const [response, setResponse] = useState(null);
    const [labelText, setLabelText] = useState([]);
    const urlParams = new URLSearchParams(window.location.search);
    // Parse start  points
    const startParam = urlParams.get('start');
    const StartCoordinates = startParam.replace(/[()\s]/g, '').split(',');
    const startCoords = StartCoordinates.map(coord => parseFloat(coord));
    // Parse end points
    const endParam = urlParams.get('end');
    const EndCoordinates = endParam.replace(/[()\s]/g, '').split(',');
    const endCoords = EndCoordinates.map(parseFloat);
    // Parse waypoints if available
    const waypointsParam = urlParams.get('WayPoints');
    const waypointStrings = waypointsParam ? waypointsParam.split('&') : [];
    const Waypoints = waypointStrings.map(waypointString => {
        const [location, stopoverString] = waypointString.split('|');
        const WayCoordinates = location.replace(/[()\s]/g, '').split(',');
        const [lat, lng] = WayCoordinates.map(parseFloat);
        const stopover = stopoverString === 'true';
        return { location: { lat, lng }, stopover };
    });

    const routeOptions = {
        origin: { lat: startCoords[0], lng: startCoords[1] },
        destination: { lat: endCoords[0], lng: endCoords[1] },
        waypoints: Waypoints,
        travelMode: 'DRIVING',
    };
    let count = useRef(0);
    const [directionsFetched, setDirectionsFetched] = useState(false);
    const directionsCallback = (res) => {
        if (res !== null && count.current < 1) {
            if (res.status === 'OK' && !directionsFetched) {
                count.current += 1;
                setResponse(res);
                setDirectionsFetched(true);
            } else {
                count.current = 0;
                console.log('res: ', res);
            }
        }
    };

    useEffect(() => {
        const Params = new URLSearchParams(window.location.search);
        const userId = Params.get('UserID');
        const zgid = Params.get('ZGID');
        setzgID(zgid)
        const postData = {
            userId: userId,
            zgid: zgid,
        };
        if (zgid !== 'null') {
            fetchData(postData);
        }
    }, []);
    const fetchData = async (postData) => {
        try {
            const result = await makeApiRequest(postData);
            setData(result);
        } catch (error) {
            console.error('API request error:', error);
        }
    };
    useEffect(() => {
        const Params = new URLSearchParams(window.location.search);
        const startPointId = Params.get('StartPointId');
        const endPointId = Params.get('EndPointId');
        const getWayPointsId = Params.get('WayPointsId');
        const waypointIds = getWayPointsId ? getWayPointsId.split('&') : [];
        let updatedArray = [];
        if (data !== null) {
            const requiredData = data?.data.AllRecords;
            const customData = data?.data.CustomModules;
            for (let i = 0; i < customData.length; i++) {
                const item = customData[i];
                for (var j = 0; j < item.ModuleData.length; j++) {
                    let innerItem = item.ModuleData[j];
                    updatedArray.push(innerItem);
                }
            }
            const mergedArray = requiredData.concat(updatedArray);

            // setAllData(mergedArray);

            let newWayPointData = [];
            for (let i = 0; i < mergedArray.length; i++) {
                if (mergedArray[i].id === startPointId) {
                    newWayPointData.push(mergedArray[i]);
                } else if (mergedArray[i].id === endPointId) {
                    newWayPointData.push(mergedArray[i]);
                } else {
                    // Create an array to accumulate waypoint data

                    for (let j = 0; j < waypointIds.length; j++) {
                        if (mergedArray[i].id === waypointIds[j]) {
                            newWayPointData.push(mergedArray[i]);
                        }
                    }
                    // Update the waypoint data outside the inner loop
                }
            }
            setWayPointData(newWayPointData);

            let labelArray = [];
            for (let i = 0; i < newWayPointData.length; i++) {
                if (i === 0) {
                    labelArray[i] = "Starting Point";
                }
                else if (i === newWayPointData.length - 1) {
                    labelArray[i] = "Destination";

                }
                else {
                    labelArray[i] = `Waypoint ${i}`;

                }
            }
            setLabelText(labelArray);

        }
    }, [data])

    const handleMarkerClick = (markerId) => {
        setOpenInfoWindowId(markerId);
    };

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <GoogleMapsLoader>
                <GoogleMap
                    id='map'
                    mapContainerStyle={{ height: '100vh', width: '100%' }}
                    center={center}
                    zoom={3}
                >
                    {/* Displaying Custom Markers and Data*/}
                    {wayPointData.map((waypoint, Key) => (
                        <div key={Key}>
                            <Marker
                                key={waypoint.id}
                                position={{
                                    lat: parseFloat(waypoint?.actmapview__MapViewLatitude.$numberDouble),
                                    lng: parseFloat(waypoint?.actmapview__MapViewLongitude.$numberDouble),
                                }}
                                icon={customMarkerIcon}
                                animation="DROP"
                                label={{
                                    text: labelText[Key],
                                    className: 'marker-label'
                                }}
                                onClick={() => handleMarkerClick(waypoint.id)}
                            // onClick={() => handleMarkerClick(waypoint.id)}
                            />
                            {openInfoWindowId === waypoint.id && (
                                <InfoWindow
                                    position={{ 
                                        lat: parseFloat(waypoint?.actmapview__MapViewLatitude.$numberDouble),
                                        lng: parseFloat(waypoint?.actmapview__MapViewLongitude.$numberDouble),
                                    }}
                                    anchor={{
                                        lat: parseFloat(waypoint?.actmapview__MapViewLatitude.$numberDouble),
                                        lng: parseFloat(waypoint?.actmapview__MapViewLongitude.$numberDouble),
                                    }}
                                    visible={openInfoWindowId === waypoint.id}
                                    onCloseClick={() => setOpenInfoWindowId(null)} // Close info window on close click
                                >
                                    <DetailsBoxUI {...waypoint} />
                                </InfoWindow>
                            )}
                        </div>
                    ))}
                    {response !== null && (
                        <>
                            <StepsSidebar response={response} wayPointData={wayPointData} />
                            <DirectionsRenderer
                            
                                options={{
                                    directions: response,
                                    suppressMarkers: zgID !== 'null' ? true : false,
                                }}
                            />
                        </>
                    )}

                    <DirectionsService
                        options={routeOptions}
                        callback={directionsCallback}
                    />
               </GoogleMap>
            </GoogleMapsLoader>

        </div>
    );
};

export default Map;
