import React, { useEffect, useState } from 'react';
import icon from '../../Assets/10.png'
import './DetailsBoxUI.css';
const DetailsBoxUI = (props) => {
    const [title, setTitle] = useState("");
    const [detailsArray, setDetailsArray] = useState([]);

    useEffect(() => {
      filterData();
    }, [])
    
    const filterData = () => {
        let Label;
        let Value;
        var Found = false;
        var NameKey = "";
        let DetailsArray = [];

        for (var k = 0; k < Object.keys(props).length; k++) {
            var Item = Object.keys(props)[k];
            
            if (
                Item.includes("$") ||
                Item.includes("id") ||
                props[Item] == null ||
                props[Item] === "undefined"
            ) {
            } else {
                
                 Label = Item.replace(/_/g, " ");
                 Value = props[Item];
    
                if (typeof props[Item] === "object") {
                    if (Item === "actmapview__MapViewLongitude") {
                        Value = props[Item].$numberDouble;
                        Label = "MapView Longitude";
                    } else if (Item === "actmapview__MapViewLatitude") {
                        Value = props[Item].$numberDouble;
                        Label = "MapView Latitude";
                    } else {
                        Value = props[Item];
                        if (Value !== null) Value = Value.name;
                    }
                }
                const obj = {
                    Label: Label,
                    Value: Value,
                }
                DetailsArray.push(obj);
            }
            if (!Found) {
                if (
                    Item.includes("name") ||
                    Item.includes("Name") ||
                    Item.includes("Title")
                ) {
                    NameKey = Item;
                    Found = true;
                }
            }
        } 
        var item = Object.keys(props);
        
        if (item.includes("Full_Name")) {
            setTitle(props.Full_Name);
        }
        else{
           setTitle(props[NameKey]);
        }
        setDetailsArray(DetailsArray);
    }
    return (
        <div>
            <div id='content' className='content'>
                <h6 id='firstHeading' className='firstHeading p-4'>
                    <img className='Headingimage' src={icon} alt="Icon" />
                    <span className='HeadingText'>{title}</span>
                </h6>
                <div className="p-2 row DetailsBox">
                    {detailsArray.map((DetailsArray, Key) => (
                        
                        <p key={Key} className='DetailBoxData'>
                            <span className='DataLabel'>
                                {DetailsArray.Label + ": "} 
                                <span className='DataValue'>
                                    {DetailsArray.Value}
                                </span>
                            </span>
                        </p>
                    ))}
                </div>

            </div>

        </div>
    )
}

export default DetailsBoxUI;