// src/utils/api.js
import axios from 'axios';

const baseURL = 'https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/map_view_app-pkykp/service/GetData/incoming_webhook/webhook0?secret=M540236598235HI';

export const makeApiRequest = async (postData) => {
  try {
    const response = await axios.post(baseURL, postData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
