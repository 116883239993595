import React from 'react';
import './StartingLoader.css';
import logo from '../../Assets/mapView-logo-white.png';
const startingLoader = ({ progress }) => {
    if (progress === 100) {
        return null; // Return null to hide the progress bar
      }
    const progressStyle = {
        width: `${progress}%`,
      };
  return (
    <div>
       <div id="initialLoaderId" className="InitialLoaderClass">
        {/* <div className="top-container"></div> */}
        <div className="center-container" id="LogoContainer">
                <img src={logo} alt="Logo"/>
                <div className="progress" role="progressbar" aria-label="Incremental Progress" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" id="progress-bar" style={progressStyle}></div>
                </div>
                <div id="progress-text" className="ProgressTextClass">{progress}%</div>                                 
         </div>
         {/* <div className="bottom-container"></div> */}
         </div>
    </div>
  )
}

export default startingLoader;