import React, { useEffect, useState } from 'react'
import './StepsSidebar.css';
const StepsSidebar = (props) => {
    const [directions, setDirections] = useState([]);
    const [wayPoint, setWayPoint] = useState([])
    useEffect(() => {

        if (props) {
            var wayPointTitle = [];
            let instructionArray = [];
            const route = props.response.routes[0];
            // route.legs.forEach(function (leg) {
            for (let i = 0; i < route.legs.length; i++) {
                var leg = route.legs[i];
                instructionArray = instructionArray.concat(leg.steps);
                if (props.wayPointData.length === 0) {
                    // console.log(leg.start_address)
                    wayPointTitle = wayPointTitle.concat(leg.start_address);
                    if(i===route.legs.length - 1){
                    wayPointTitle = wayPointTitle.concat(leg.end_address);
                    }
                }
            }
            // })
            // console.log(wayPointTitle);
            setDirections(instructionArray);


            if (props.wayPointData.length !== 0) {
                var NameKey = "";
               
                for (let i = 0; i < props.wayPointData.length; i++) {
                    var Found = false;
                    var Item = Object.keys(props.wayPointData[i]);
                    for (let j = 0; j < Item.length; j++) {
                        var item = Item[j];
                        if (item.includes("Full_Name")) {
                            wayPointTitle[i] = props.wayPointData[i].Full_Name;
                            Found = true;
                        }
                        if(!Found){
                        if (
                            item.includes("name") ||
                            item.includes("Name") ||
                            item.includes("Title")
                        ) {
                            NameKey = item;
                            wayPointTitle[i] = props.wayPointData[i][NameKey];
                            Found = true;
                           
                        }
                    }
                    }

                }
            }

            setWayPoint(wayPointTitle);


            // Calculate the total distance in meters
            //   const totalDistance = props.response.routes[0].legs.reduce((acc, leg) => {
            //     return acc + leg.distance.value;
            //   }, 0);
            // Convert meters to kilometers or miles if needed
            //   setDistance(totalDistanceInKilometers);
        }
    }, [props]);
    return (
        <div>
            <div className="Sidebar">
                <div className="Header">
                    {wayPoint.map((Title, Key) => (
                        <h6 className='WayPoints' key={Key}>{Title}</h6>
                    ))}
                </div>
                <div className='StepsInstruction'>
                    {directions && (
                        <ul className='unorderList'>
                            {directions.map((step, Key) => (
                                <li className='StepsList' key={Key} dangerouslySetInnerHTML={{ __html: step.instructions }}></li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>

        </div>
    )
}

export default StepsSidebar;