import './App.css';
import React , {useEffect, useState} from 'react';
import StartingLoader from './components/startingLoader/StartingLoader.jsx'
import GoogleMap from './components/googleMap/GoogleMap.jsx'
function App() {
  const [progress, setProgress] = useState(0);
  let loadMap = false;
  useEffect(() => { 
    // Simulate progress incrementing over time
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 25;
        return newProgress > 100 ? 100 : newProgress;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  if (progress === 100){
    loadMap = true;
  }
  return (
    <div className="App">
      <StartingLoader progress={progress} />
      <div style = {{display: loadMap ? 'block' : 'none'}}>
      <GoogleMap />
      </div>
      
    </div>
  );
}

export default App;
