import { LoadScript } from '@react-google-maps/api';

const GoogleMapsLoader = ({ children }) => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyCwX3HYkOm4i_dO3CIN_evEr5Cy8aeyDDo">
      {children}
    </LoadScript>
  );
};

export default GoogleMapsLoader;
